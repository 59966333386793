import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../app.breadcrumb.service';
import { JobsService } from '../jobs.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {UploadDialogComponent} from '../../photos/upload-dialog/upload-dialog.component';
import { Loading } from 'notiflix';
import { ISqlJob} from '../sql-job.interface';
import { Clipboard } from '@angular/cdk/clipboard';

import {Form} from '../../forms/form.interface';
import {FormEditorComponent} from '../../forms/form-editor/form-editor.component';
import {UploadComponent} from '../../photos/upload/upload.component';
import {InspectionFormDialogComponent} from '../../forms/inspection-form-dialog/inspection-form-dialog.component';
import {catchError} from 'rxjs/operators';
import {findAll} from '@angular/compiler-cli/ngcc/src/utils';
import {log} from 'util';

@Component({
  selector: 'app-jobs-manager',
  templateUrl: './jobs-manager.component.html',
  styleUrls: ['./jobs-manager.component.scss']
})
export class JobsManagerComponent implements OnInit {
  jobs: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private jobsService: JobsService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private clipboard: Clipboard ) {
      this.breadcrumbService.setItems([
        {label: 'Jobs Manager', routerLink: ['/']}
      ]);
    }

  ngOnInit(): void {
    Loading.Standard('Loading Job List...');
    this.jobsService.getJobs()
      .then(jobs => {
        this.jobs = jobs;
        Loading.Remove();
      });
  }

  sendLinkEmail(job: ISqlJob, event): void {
    if (!event.shiftKey) {
      this.confirmationService.confirm({
        key: 'jobsManagerConfirmDialog',
        message: `Are you sure you want to send the link for Job# ${job.scJobId}?`,
        accept: () => {
          console.log('Sending email to assigned rep...');
          this.jobsService.sendLinkEmail(job.jobId, job.scJobId)
            .then(res => {
              job.trackingStatus = res.status;
              this.messageService.add({severity: 'success', summary: 'Email sent to inspector', detail: job.scRepEmail});
            });
        }
      });
    } else {
      this.jobsService.getInspectionFormLink(job.jobId, job.scJobId)
        .then(data => {
          if (data) {
            if ( this.clipboard.copy(data.link.toString()) ) {
              this.messageService.add({
                severity: 'success',
                summary: 'Link copied',
                detail: 'You can now paste the link anywhere you like.'
              });
            } else {
              this.messageService.add({
                severity: 'warning',
                summary: 'Link Logged to Console',
                detail: 'Check the developer console for the link.'
              });
            }
            console.log(data.link);
          }
        });
    }

  }

  uploadInspection(job): void {
    this.confirmationService.confirm({
      key: 'jobsManagerConfirmDialog',
      message: `Are you sure you want to upload job# ${job.scJobId} to the legacy server?`,
      accept: async () => {
        Loading.Standard('Transferring Photos...');
        this.jobsService.transferJobToLegacy(job.jobId)
          .then(res => {
            Loading.Remove();
            if (res.data.sql) {
              this.messageService.add({
                severity: 'success',
                summary: 'Job Photos Transferred',
                detail: 'You can now finish processing the job in the legacy system.'
              });
            } else {
              this.messageService.add({
                severity: 'info',
                summary: 'Nothing Transferred',
                detail: 'There were no photos found for upload to the legacy system.'
              });
            }
          })
          .catch(err => {
            console.log(`Unable to upload inspection data to legacy system.\n${err}`);
            Loading.Remove();
            this.messageService.add({
              severity: 'error', summary: 'Unable to Upload...',
              detail: 'Unable to upload inspection data to legacy system.'
            });
          })
          .finally(() => {
            Loading.Remove();
          });
      }
    });
  }

  openInspectionForm(job: ISqlJob): void {
    console.log('Clicked edit inspection form.', job);
    const ref = this.dialogService.open(InspectionFormDialogComponent, {
      data: {
        job
      },
      header: `Editing Inspection Form for Job# ${job.scJobId}`,
      width: '85%',
      showHeader: true,
      closeOnEscape: false,
    });

    ref.onClose.subscribe(() => {
      // if (modifiedForm) {
      //   console.log(modifiedForm);
      //   form = modifiedForm;
      //   this.messageService.add({severity: 'info', summary: 'Inspection form saved', detail: modifiedForm.name});
      // }
    });
  }

  openPhotoUpload(job: ISqlJob): void {
    console.log('Clicked upload photos.', job);
    const ref = this.dialogService.open(UploadDialogComponent, {
      data: {
        job
      },
      header: `Upload Photos for Job# ${job.scJobId}`,
      width: '85%',
      showHeader: true,
      closeOnEscape: false,
    });

    ref.onClose.subscribe(() => {
      // if (modifiedForm) {
      //   console.log(modifiedForm);
      //   form = modifiedForm;
      //   this.messageService.add({severity: 'info', summary: 'Inspection form saved', detail: modifiedForm.name});
      // }
    });
  }

}
