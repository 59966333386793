<div class="login-body">
    <div class="login-panel p-fluid">
        <div class="login-panel-header">
            <img src="assets/layout/images/login/logo-sapphire-color.png">
        </div>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
        <p-toast position="top-center"></p-toast>
        <div class="login-panel-content">
            <div class="p-grid">
                <div class="p-col-12">
                    <h1>CSI SITECHECK INSPECTION</h1>
                    <h2>Welcome, please use the form to sign in</h2>
                </div>
                <div class="p-col-12">
                    <span class="p-float-label">
                        <input id="username" type="text" pInputText ngModel name="username">
                        <label for="username">Username</label>
                    </span>
                </div>
                <div class="p-col-12">
                    <span class="p-float-label">
                        <input id="password" type="password" pInputText ngModel name="password">
                        <label for="password">Password</label>
                    </span>
                </div>
                <div class="p-col-6" style="padding-top: 1.25em">
<!--                    <a href="#">Forget Password?</a>-->
                  <p>Forgot Password?</p>
                </div>
                <div class="p-col-6" style="text-align: right">
                    <button pButton pRipple type="submit" [disabled]="!loginForm.valid" label="NEXT" style="width: 100%"></button>
                </div>
            </div>
        </div>
      </form>
    </div>
</div>
