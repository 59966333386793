<div class="p-grid">
  <div class="p-col">
    <image-cropper
      [imageFile]="file"
      [imageQuality]="100"
      [imageChangedEvent]="imageCropped"
      [maintainAspectRatio]="false"
      [containWithinAspectRatio]="true"
      [transform]="transform"
      [autoCrop]="true"
      [canvasRotation]="canvasRotation"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady(sourceImageDimensions)"
      (loadImageFailed)="loadImageFailed()"
      [style.display]="showCropper ? null : 'none'">
    </image-cropper>
    <div style="text-align: center">
<!--      <button pButton pRipple type="button" icon="pi pi-window-minimize" (click)="crop()"-->
<!--              class="p-button-rounded p-button-text p-button-info"-->
<!--              pTooltip="Crop" tooltipPosition="top"-->
<!--              showDelay="500" hideDelay="150"></button>-->
      <button pButton pRipple type="button" icon="pi pi-replay" (click)="rotate('left')"
              class="p-button-rounded p-button-text p-button-info"
              pTooltip="Rotate Left" tooltipPosition="top"
              showDelay="500" hideDelay="150"></button>
      <button pButton pRipple type="button" icon="pi pi-refresh" (click)="rotate('right')"
              class="p-button-rounded p-button-text p-button-info"
              pTooltip="Rotate Right" tooltipPosition="top"
              showDelay="500" hideDelay="150"></button>
      <button pButton pRipple type="button" icon="pi pi-plus" (click)="zoomIn()"
              class="p-button-rounded p-button-text p-button-info"
              pTooltip="Zoom In" tooltipPosition="top"
              showDelay="500" hideDelay="150"></button>
      <button pButton pRipple type="button" icon="pi pi-minus" (click)="zoomOut()"
              class="p-button-rounded p-button-text p-button-info"
              pTooltip="Zoom Out" tooltipPosition="top"
              showDelay="500" hideDelay="150"></button>
      <button pButton pRipple type="button" icon="pi pi-check" (click)="save()"
              class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
      <button pButton pRipple type="button" icon="pi pi-times" (click)="cancel()"
              class="p-button-rounded p-button-text p-button-danger"></button>
    </div>


  </div>
</div>
