import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Note } from './classes/note.class';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private http: HttpClient) { }

  getScJobNotesById(scJobId: number): Promise<Note[]> {
    return this.http.get(`/api/notes/${scJobId}`)
      .toPromise()
      .then(data => {
        return data as Note[];
      });
  }

  createScJobNote(scJobId: number, scJobNote: string): Promise<void> {
    return this.http.post(`/api/notes/${scJobId}`, { scJobNote })
      .toPromise()
      .then(() => { return; })
      .catch(e => console.error(`Unable to create job note on form save and submit as final.\n${e}`));
  }
}

