<p-toast></p-toast>
<p-confirmDialog key="jobsManagerConfirmDialog" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="p-grid">
  <div class="p-col-12">
    <div class="p-panel">
      <div class="p-panel-header">
        <h4>Jobs Manager</h4>
      </div>
      <div class="p-panel-content">
        <p-table #dt1 [value]="jobs" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['scJobId']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search job #" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="JobID">
                Job# <p-sortIcon field="scJobId"></p-sortIcon>
                <p-columnFilter type="number" field="scJobId" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scCompanyName">
                Company <p-sortIcon field="scCompanyName"></p-sortIcon>
                <p-columnFilter type="text" field="scCompanyName" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scFormTypeName">
                Form <p-sortIcon field="scFormTypeName"></p-sortIcon>
                <p-columnFilter type="text" field="scFormTypeName" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scRequestDate">
                Requested <p-sortIcon field="scRequestDate"></p-sortIcon>
                <p-columnFilter type="date" field="scRequestDate" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scScheduledDate">
                Scheduled <p-sortIcon field="scScheduledDate"></p-sortIcon>
                <p-columnFilter type="date" field="scScheduledDate" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scInsuredName">
                Insured Name <p-sortIcon field="scInsuredName"></p-sortIcon>
                <p-columnFilter type="text" field="scInsuredName" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scInsuredAddress">
                Insured Address <p-sortIcon field="scInsuredAddress"></p-sortIcon>
                <p-columnFilter type="text" field="scInsuredAddress" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scCoordinatorName">
                Coordinator <p-sortIcon field="scCoordinatorName"></p-sortIcon>
                <p-columnFilter type="text" field="scCoordinatorName" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scRepName">
                Rep Name <p-sortIcon field="scRepName"></p-sortIcon>
                <p-columnFilter type="text" field="scRepName" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scJobStatus">
                Job Status <p-sortIcon field="scJobStatus"></p-sortIcon>
                <p-columnFilter type="text" field="scJobStatus" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="trackingStatus">
                Form Status <p-sortIcon field="trackingStatus"></p-sortIcon>
                <p-columnFilter type="text" field="trackingStatus" display="menu"></p-columnFilter>
              </th>
              <th style="width:15rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-job>
            <tr>
              <td>{{job.scJobId}}</td>
              <td>{{job.scCompanyName}}</td>
              <td>{{job.scFormTypeName}}</td>
              <td>{{job.scRequestDate | date: 'short'}}</td>
              <td>{{job.scScheduledDate | date: 'short'}}</td>
              <td>{{job.scInsuredName}}</td>
              <td>{{job.scInsuredAddress}}</td>
              <td>{{job.scCoordinatorName}}</td>
              <td>{{job.scRepName}}</td>
              <td>{{job.scJobStatus}}</td>
              <td><span [class]="'product-badge status-' + job.trackingStatus.toLowerCase()">{{job.trackingStatus}}</span></td>
              <td style="text-align:center">
                <button pButton pRipple type="button" icon="pi pi-envelope" (click)="sendLinkEmail(job, $event)"
                  class="p-button-rounded p-button-text" pTooltip="Email inspection form link to assigned rep"
                  tooltipPosition="top" showDelay="500" hideDelay="150"></button>
                <button pButton pRipple type="button" icon="pi pi-file" (click)="openInspectionForm(job)"
                  class="p-button-rounded p-button-text p-button-success" [disabled]="!job.formId"
                  pTooltip="Open inspection form for review and editing" tooltipPosition="top" showDelay="500"
                  hideDelay="150"></button>
                <button pButton pRipple type="button" icon="pi pi-image" (click)="openPhotoUpload(job)"
                  class="p-button-rounded p-button-text p-button-success p-mr-2"
                  pTooltip="Open the photo upload form for review and editing" tooltipPosition="top" showDelay="500"
                  hideDelay="150"></button>
                <button pButton pRipple type="button" icon="pi pi-cloud-upload" (click)="uploadInspection(job)"
                  class="p-button-rounded p-button-text p-button-danger"
                  pTooltip="Upload completed inspection and photos to SiteCheck system" tooltipPosition="top"
                  showDelay="500" hideDelay="150"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
