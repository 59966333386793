import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <div class="p-grid">
                <div class="p-col">
                    <img src="assets/layout/images/csi-sitecheck.png" alt="sapphire-layout" />
                    <div class="layout-footer-appname">&nbsp;&nbsp;Inspection Manager</div>
                </div>
                <div class="p-col p-col-align-right">
                    <span>(C) 2020 - Collateral Specialists</span>
                </div>
            </div>
        </div>
    `
})
export class AppFooterComponent {

}
