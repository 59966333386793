import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { exhaustMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let userAuth = localStorage.getItem('userAuth');
    if (userAuth) {
      let authJson = JSON.parse(userAuth);
      // do not add auth header for aws put
      if (request.url.includes('/inspection-photos/0')) {
        return next.handle(request);
      }
      const modifiedReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authJson.accessToken}`),
      });
      return next.handle(modifiedReq);
    }
    else{
      return next.handle(request);
    }
  }
}
