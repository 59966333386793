import {Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef} from '@angular/core';
import {FormsService} from '../forms.service';
import {ISqlJob} from '../../jobs/sql-job.interface';
import {JobsService} from '../../jobs/jobs.service';
import {Job} from '../../jobs/job';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { Loading } from 'notiflix';
import { NotesService } from '../../notes/notes.service';

@Component({
  selector: 'app-inspection-form',
  queries: {
    formViewContent: new ViewChild( `formViewContent`)
  },
  templateUrl: './inspection-form.component.html',
  styleUrls: ['./inspection-form.component.scss']
})
export class InspectionFormComponent implements OnInit {
  form: any;
  formDef: any;
  formData: any = {};
  isSaveFinal = false;
  isFormClosed = false;
  isFormValid = true;

  formioOptions = {
    hooks: {
      beforeSubmit: (submission, next) => {
        if (this.isFormValid) {
          next(null, submission);
        } else {
          this.formViewContentRef.nativeElement.scrollTo( 0, 0 );
        }
      }
    }
  };

  @Input()
  scJob: ISqlJob;

  job: Job;

  @Output()
  closeEvent: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  hasUnsavedFormChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  public formViewContentRef!: ElementRef;

  constructor(
    private formsService: FormsService, private jobsService: JobsService, private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private notesService: NotesService,
  ) { }

  ngOnInit(): void {
    // console.log(this.config);
    // this.scJob = this.config.data.job;
    if (this.scJob) {
      this.formsService.getFormById(this.scJob.formId)
        .then(form => {
          this.form = form;
          if (form) {
            // @ts-ignore
            this.formDef = JSON.parse(form.inspectionForm);
            return this.jobsService.getJobById(this.scJob.jobId);
          }
        })
        .then(job => {
          if (job) {
            // @ts-ignore
            this.job = job;
            // @ts-ignore
            if (job.inspectionData) {
              this.formData = {
                data: {
                  ...this.scJob,
                  tagFormTypeId: this.scJob.scFormTypeId,
                  tagRefNum: this.scJob.scJobId,
                  tagUid: this.scJob.scRepUserId,
                  // @ts-ignore
                  ...job.inspectionData
                }
              };
            } else {
              this.formData = {
                data: this.scJob
              };
            }
          }
        });
    }
  }

  cancel(event: MouseEvent): void {
    // this.ref.close(null);
  }

  saveFinal(formio: any, formData: any): void {
    this.confirmationService.confirm({
      key: 'formConfirmDialog',
      message: `Are you sure you want to save as final and submit the form for review?`,
      reject: () => {
        formio.formio.emit('submitError', `Form submit as final was canceled.`);
      },
      accept: () => {
        Loading.Standard('Saving and Submitting for Review...');
        // @ts-ignore
        this.jobsService.updateJob(this.job._id, {status: 'QC', inspectionData: formData})
          .then(res => {
            // @ts-ignore
            return this.jobsService.postFormDataLegacy(this.job._id);
          })
          .then(res => {
            this.messageService.add({
              severity: 'success',
              summary: 'Saved as Final Submit...',
              detail: 'The form has been submitted successfully.'
            });
            this.notesService.createScJobNote(this.job.scJobId,
              `Inspector has completed the data entry process and submitted the finalized report. The status was updated to QC. `)
                .catch(e => console.error(`Unable to save note on final submit and save.\n${e}`));
            this.isSaveFinal = true;
            Loading.Remove();
            formio.formio.emit('submitDone', `Form successfully submitted as final. You can now close the form.`);
            this.hasUnsavedFormChanges.emit(false);
            this.closeEvent.emit('onClose');
          })
          .catch (e => {
            console.log(`Unable to save and submit final.\n${e}`);
            formio.formio.emit('submitError', `Unable to save and submit final / ${e}`);
            Loading.Remove();
            this.messageService.add({
              severity: 'error', summary: 'Unable Save & Submit as Final...',
              detail: 'The form was not submitted. Please try again or contact your coordinator for help.'
            });
          })
          .finally( () => {
            Loading.Remove();
          });
      }
    });
  }

  onFormChange(e): void {
    if (e.isModified) {
      this.hasUnsavedFormChanges.emit(true);
    }
  }

  onSubmit(formio: any, submission: any): void {
    this.saveFinal(formio, submission.data);
  }

  onCustomEvent(formio: any, event: any): void {
    switch (event.type) {
      case 'save':
        Loading.Standard('Saving Form Progress...');
        // @ts-ignore
        this.jobsService.updateJob(this.job._id, {inspectionData: event.data})
          .then(res => {
            Loading.Remove();
            this.messageService.add({
              severity: 'success',
              summary: 'Save Successful...',
              detail: 'Form progress has been saved successfully.'
            });
            this.hasUnsavedFormChanges.emit(false);
          })
          .catch(e => {
            console.log(`Unable to save the form.\n${e}`);
            // formio.formio.emit('submitError', `Unable to save the form / ${e}`);
            Loading.Remove();
            this.messageService.add({
              severity: 'error', summary: 'Unable Save Form Progress...',
              detail: 'Form progress was not saved. Please try again or contact your coordinator for help.'
            });
          })
          .finally(() => {
            Loading.Remove();
          });
        break;

      case 'saveFinal':
        this.saveFinal(formio, event.data);
        break;

      case 'close':
        this.confirmationService.confirm({
          key: 'formConfirmDialog',
          message: `Are you sure you want to close the form?`,
          accept: () => {
            // this.ref.close(null);
            this.isFormClosed = true;
            this.closeEvent.emit('onClose');
          }
        });
        break;
    }
  }

  reopenForm(): void {
    this.isFormClosed = false;
  }

  onFormInvalid(e): void {
    console.log(e);
    this.isFormValid = false;
    this.formViewContentRef.nativeElement.scrollTo( 0, 0 );
  }

}
