import { Component, OnInit } from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {DomSanitizer} from '@angular/platform-browser';
// import { base64ToFile } from 'ngx-image-cropper/public-api';

@Component({
  selector: 'app-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent implements OnInit {
  file: any;
  showCropper: boolean;
  transform: ImageTransform = {
    scale: 1,
    rotate: 0,
    flipH: false,
    flipV: false,
  };
  croppedImage: any;
  canvasRotation = 0;
  sourceImageDimensions: any;
  scale: number;
  rotation = 0;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,  private sanitizer: DomSanitizer,
    ) {
      this.scale = 1;

  }

  ngOnInit(): void {
    if (this.config.data.file) {
      this.file = this.config.data.file;
      this.sourceImageDimensions = {width: 640, height: 480};
    }
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  cropperReady(sourceImageDimensions: Dimensions): void {
    this.sourceImageDimensions = sourceImageDimensions;
  }

  resetImage(): void {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  rotate(direction): void {
    if (direction === 'left') {
      this.canvasRotation--;
    } else {
      this.canvasRotation++;
    }
    this.flipAfterRotate();
  }

  private flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  save(): void {
    const imageFile: any = new File([base64ToFile(this.croppedImage)], this.file.name, { type: this.file.type });
    imageFile.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(imageFile)));
    imageFile.resized = this.file.resized;
    imageFile.id = this.file.id;
    imageFile.url = this.file.url;
    imageFile.uploaded = false;
    imageFile.progress = 0;
    imageFile.key = this.file.key;
    imageFile.label = this.file.label;
    imageFile.bucket = this.file.bucket;
    this.ref.close(imageFile);
  }

  cancel(): void {
    this.ref.close(this.file);
  }

  loadImageFailed(): void {
    console.log('Unable to load image into the crop/resize editor.');
  }
}
