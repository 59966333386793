import { Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {FormsService} from '../forms.service';
import {Form} from '../form.interface';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import 'node_modules/jsoneditor';
import emptyFormDef from './empty-form-def';
import { findAll } from 'obj-traverse/lib/obj-traverse';
import {findAllMatchingNodes} from '@angular/compiler-cli/src/ngtsc/typecheck/src/comments';
import { cloneDeep } from 'lodash';
import { FormioUtils } from '@formio/angular';
import { filterDeep, eachDeep } from 'deepdash-es/standalone';
import { Loading } from 'notiflix';

@Component({
  selector: 'app-form-editor',
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.scss']
})
export class FormEditorComponent implements OnInit, OnDestroy {
  form: Form;
  formDef: any;
  updatedFormDef: object;
  formJson: string;
  jsonEditorFormDef: any;
  public editorOptions: JsonEditorOptions;
  @ViewChild('jsoneditor') jsonEditor: JsonEditorComponent;
  formInputComponents: any[];
  currentTabIndex = 0;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private formsService: FormsService) { }

  ngOnInit(): void {
    console.log(this.config);
    if (this.config.data.form) {
      this.form = this.config.data.form;
      if (this.form.inspectionForm) {
        this.formJson = this.form.inspectionForm;
        this.formDef = JSON.parse(this.form.inspectionForm);
        this.addCustomApiProps(this.formDef);
      } else {
        this.formJson = this.form.inspectionForm;
        this.formDef =  JSON.parse(emptyFormDef);
      }
      // setup json editor
      this.editorOptions = new JsonEditorOptions();
      this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
      // this.options.mode = 'code'; //set only one mode
    }
  }

  ngOnDestroy(): void {
    this.formInputComponents = [];
  }

  async save(event: MouseEvent): Promise<void> {
    if (this.updatedFormDef) {
      this.formDef = cloneDeep(this.updatedFormDef);
      this.updatedFormDef = null;
    }
    if (this.jsonEditorFormDef) {
      this.formDef = cloneDeep(this.jsonEditorFormDef);
      this.jsonEditorFormDef = null;
    }
    this.addCustomApiProps(this.formDef);
    this.form.inspectionForm = JSON.stringify(this.formDef);
    const form = await this.formsService.updateForm(this.form);
    this.ref.close(form);
  }

  cancel(event: MouseEvent): void {
    this.ref.close(null);
  }

  onChange(event: any): void {
    this.updatedFormDef = event.form;
  }

  jsonEditorChange($event): void {
    console.log($event);
    // if ($event) {
    //   this.jsonEditorFormDef = $event;
    // }
  }

  tabChange($event): void {
    // handle changes from the form editor
    if ($event.index !== 0 && this.updatedFormDef) {
      this.formDef = cloneDeep(this.updatedFormDef);
      this.updatedFormDef = null;
      this.addCustomApiProps(this.formDef);
    }
    // handle changes from json editor
    if ($event.index !== 2 && this.jsonEditorFormDef) {
      this.formDef = cloneDeep(this.jsonEditorFormDef);
      this.jsonEditorFormDef = null;
      this.addCustomApiProps(this.formDef);
    }
    this.currentTabIndex = $event.index;
  }

  addCustomApiProps(form: any): void {
    // Loading.Standard('Processing Input Fields...');
    this.formInputComponents = [];
    eachDeep(form, (val, key, parent, ctx) => {
      if (!ctx.isCircular) {
        if (key === 'input' && val === true && parent.type !== 'button'){
          if (!parent.hasOwnProperty('properties')) {
            parent.properties = { pdfFormKey: 'null' };
          }
          if (![parent].hasOwnProperty('componentId')) {
            parent.componentId = FormioUtils.getRandomComponentId();
          }
          this.formInputComponents.push(parent);
        }
      }
    });
    // Loading.Remove();
  }

  onRowEditSave(formInputItem): void {
    console.log(formInputItem);
  }

}
