import {Component, HostListener, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobsService } from '../jobs/jobs.service';
import {ISqlJob} from '../jobs/sql-job.interface';
import {ConfirmationService} from 'primeng/api';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit {
  canNavigateAwaySubject: Subject<boolean> = new Subject<boolean>();

  scJob: ISqlJob;
  isLoading = true;
  hasUnsavedPhotoChanges = false;
  hasUnsavedFormChanges = false;
  isInspectionImmutable = false;
  immutableInspectionStatusIds = [1, 3, 6, 7, 8, 9];
  isLinkValid = false;
  pageTitle = 'Invalid SiteCheck Inspection Form Link';

  @HostListener('window:beforeunload', ['$event'])
  public onPageUnload($event: BeforeUnloadEvent): void {
    if (this.hasUnsavedChanges()) {
      $event.returnValue = true;
    }
  }

  constructor(private activatedRoute: ActivatedRoute,
              private confirmationService: ConfirmationService,
              // private router: Router,
              private jobsService: JobsService
              ) { }

  ngOnInit(): void {
    const jobId = this.activatedRoute.snapshot.paramMap.get('jobId');
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    this.jobsService.getScJobByJobIdJwt(jobId, token)
      .then(scJob => {
        if (scJob) {
          // @ts-ignore
          this.scJob = scJob;
          this.pageTitle = `CSI/SiteCheck Inspection Submission for Job # ${scJob.scJobId}`;
          this.isInspectionImmutable = this.immutableInspectionStatusIds.includes(this.scJob.scJobStatusId);
          this.isLinkValid = true;
        }
        this.isLoading = false;
      });
  }

  hasUnsavedChanges(): boolean {
    return this.hasUnsavedFormChanges || this.hasUnsavedPhotoChanges;
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.hasUnsavedChanges()) {
      let work = `work`;
      if (this.hasUnsavedFormChanges && this.hasUnsavedPhotoChanges) {
        work = 'photos and inspection form changes';
      } else if (this.hasUnsavedFormChanges && !this.hasUnsavedPhotoChanges) {
        work = `inspection form changes`;
      } else if (this.hasUnsavedPhotoChanges && !this.hasUnsavedFormChanges) {
        work = `photo changes`;
      }
      this.confirmationService.confirm({
        key: 'inspectionConfirmDialog',
        message: `It appears you have unsaved ${work}, are you sure you want to leave this screen?`,
        defaultFocus: 'reject',
        closeOnEscape: true,
        accept: () => {
          this.canNavigateAwaySubject.next(true);
        },
        reject: () => {
          this.canNavigateAwaySubject.next(false);
        }
      });
      return this.canNavigateAwaySubject;
    }
    return true;
  }

}
