import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotesService } from '../notes/notes.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
    private http: HttpClient,
    private notesService: NotesService,
  ) { }

  getJobs(): Promise<any> {
    return this.http.get('/api/jobs')
      .toPromise()
      .then( data => {
        return data;
      });
  }

  sendLinkEmail(jobId: string, scJobId: number): Promise<any> {
    return this.http.get(`/api/jobs/send-link-email/${jobId}`)
      .toPromise()
      .then( data => {
        this.notesService.createScJobNote(scJobId,
          `Job inspection form and photo upload link emailed to inspector.`)
          .catch(e => console.error(`Unable to save note for inspection form and photo upload link email.\n${e}`));
        return data;
      });
  }

  getInspectionFormLink(jobId: string, scJobId: number): Promise<any> {
    return this.http.get(`/api/jobs/inspection-form-link/${jobId}`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getJobById(jobId: string): Promise<any> {
    return this.http.get(`/api/jobs/${jobId}`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getScJobByJobId(jobId: string): Promise<any> {
    return this.http.get(`/api/jobs/sc-job/${jobId}`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getScJobByJobIdJwt(jobId: string, jwt: string): Promise<any> {
    return this.http.post(`/api/jobs/sc-job-jwt/${jobId}`, { token: jwt })
      .toPromise()
      .then( data => {
        return data;
      });
  }

  updateJob(jobId: string, data: any): Promise<any> {
    return this.http.put(`/api/jobs/${jobId}`, data)
      .toPromise()
      .then( resp => {
        return resp;
      });
  }

  transferJobToLegacy(jobId: string): Promise<any> {
    return this.http.get(`/api/legacy-transfer/push-photos/${jobId}`)
      .toPromise()
      .then(results => {
        return results;
      });
  }

  postFormDataLegacy(jobId: string): Promise<any> {
    return this.http.get(`/api/jobs/post-form-data-legacy/${jobId}`)
      .toPromise()
      .then( resp => {
        return resp;
      });
  }

}
