<p-confirmDialog key="formConfirmDialog" header="Form Action Confirmation" width="425" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="p-panel" *ngIf="!isSaveFinal && !isFormClosed" >
  <div #formViewContent class="p-panel-content">
    <formio #f [form]="formDef" [submission]="formData"
            (submit)="onSubmit(f, $event)"
            (change)="onFormChange($event)"
            (errorChange)="onFormInvalid($event)"
            [formioOptions]="formioOptions"
            (customEvent)="onCustomEvent(f, $event)"></formio>
  </div>
</div>
<div class="p-panel" *ngIf="isSaveFinal">
  <div class="p-panel-content">
    <h5>Thank you for completing this inspection form. Your form has been been saved and submitted for review.</h5>
    <h5>If you are finished uploading photos, you can close this browser tab.</h5>
  </div>
</div>
<div class="p-panel" *ngIf="isFormClosed">
  <div class="p-panel-content">
    <h5>The inspection form has been closed. You can reopen it by clicking the button below.</h5>
    <h5>If you are finished uploading photos, you can close this browser tab.</h5>
    <p-button (onClick)="reopenForm()">Open Inspection Form</p-button>
  </div>
</div>
