import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { IFile } from './upload/upload.models';
import {Observable} from 'rxjs';
import {AppConfigService} from '../app-config.service';
import {PhotoLabel} from '../settings/photo-labels/classes/photo-label.class';
import {SettingsService} from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class PhotosService {
  awsBasePath: string;

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private settingsService: SettingsService
  ) {
    this.awsBasePath = appConfigService.awsBaseUrl;
  }

  getSignedUrls(files: IFile[]): Promise<any> {
    return this.http.post(`/api/aws/sign-for-put/`, files)
      .toPromise()
      .then( data => {
        return data;
      });
    }

    uploadPhotoS3(file: any): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': file.type,
      });
      const fileUrl = this.awsBasePath
        ? `${this.awsBasePath}${new URL(file.url).pathname}?${file.url.split('?')[1]}`
        : file.url;
      const uploadReq = new HttpRequest(
        'PUT',
        fileUrl,
        file,
        {
          headers,
          reportProgress: true,
        }
      );
      return this.http.request(uploadReq);
    }

    savePhotos(jobId, photos): Promise<any> {
      return this.http.put(`/api/jobs/${jobId}`, { inspectionPhotos: photos })
        .toPromise()
        .then(job => {
          return job;
        });
    }

    getJob(jobId): Promise<any> {
      return this.http.get(`/api/jobs/${jobId}`)
        .toPromise()
        .then(job => {
          return job;
        });
    }

    getS3Photo(url: string): Promise<any> {
      return this.http.get(url, { responseType: 'arraybuffer'})
        .toPromise()
        .then(res => {
          // console.log(res);
          return res;
        });
    }

    deleteS3Photo(bucket, key): Promise<any> {
      return this.http.post('/api/aws/delete-s3-object', { bucket, key })
        .toPromise()
        .then(res => {
          // console.log(res);
          return res;
        });
    }

    getS3PhotoBucket(): Promise<any> {
      return this.http.get('/api/aws/get-s3-bucket-name')
        .toPromise()
        .then( data => {
          // @ts-ignore
          return data.bucket;
        })
        .catch( err => {
          console.log(err);
        });
    }

    getPhotoLabels(): Promise<PhotoLabel[]> {
      return this.settingsService.getPhotoLabels()
        .then(photoLabels => {
          return photoLabels;
        });

      // return [
      //   { name: 'Additional Building', value: 'Additional Building'},
      //   { name: 'Address Verification', value: 'Address Verification'},
      //   { name: 'Bar', value: 'Bar'},
      //   { name: 'Bedroom', value: 'Bedroom'},
      //   { name: 'Bathroom', value: 'Bathroom'},
      //   { name: 'Break Room', value: 'Break Room'},
      //   { name: 'Building Front', value: 'Building Front'},
      //   { name: 'Building Rear', value: 'Building Rear'},
      //   { name: 'Building Side', value: 'Building Side'},
      //   { name: 'Burglar Alarm', value: 'Burglar Alarm'},
      //   { name: 'Business License', value: 'Business License'},
      //   { name: 'Child Safety Item', value: 'Child Safety Item'},
      //   { name: 'Common Area', value: 'Common Area'},
      //   { name: 'Contractor\'s Equipment', value: 'Contractor\'s Equipment'},
      //   { name: 'Dance Floor', value: 'Dance Floor'},
      //   { name: 'Dancer Stage/Platform/Pole', value: 'Dancer Stage/Platform/Pole'},
      //   { name: 'Dealer Plate/License', value: 'Dealer Plate/License'},
      //   { name: 'Deferred Maintenance', value: 'Deferred Maintenance'},
      //   { name: 'Dining Room', value: 'Dining Room'},
      //   { name: 'Driveway/Sidewalks', value: 'Driveway/Sidewalks'},
      //   { name: 'Electrical Panel', value: 'Electrical Panel'},
      //   { name: 'Emergency Lighting', value: 'Emergency Lighting'},
      //   { name: 'Entertainment Devices', value: 'Entertainment Devices'},
      //   { name: 'Exit Signs', value: 'Exit Signs'},
      //   { name: 'Exterior/Property', value: 'Exterior/Property'},
      //   { name: 'Exterior Lighting', value: 'Exterior Lighting'},
      //   { name: 'Fence/Gate/Post & Cables', value: 'Fence/Gate/Post & Cables'},
      //   { name: 'Fire Alarm', value: 'Fire Alarm'},
      //   { name: 'Fire Extinguisher', value: 'Fire Extinguisher'},
      //   { name: 'Fire Hydrant/Fire Prevention', value: 'Fire Hydrant/Fire Prevention'},
      //   { name: 'Fire Suppression System', value: 'Fire Suppression System'},
      //   { name: 'Garage', value: 'Garage'},
      //   { name: 'Interior', value: 'Interior'},
      //   { name: 'Key Cabinet', value: 'Key Cabinet'},
      //   { name: 'Kitchen', value: 'Kitchen'},
      //   { name: 'Laundry Room/Utility Room', value: 'Laundry Room/Utility Room'},
      //   { name: 'Liquor License', value: 'Liquor License'},
      //   { name: 'Living Room', value: 'Living Room'},
      //   { name: 'Lobby', value: 'Lobby'},
      //   { name: 'Marina/Boat Docks', value: 'Marina/Boat Docks'},
      //   { name: 'Motion Sensor', value: 'Motion Sensor'},
      //   { name: 'Office', value: 'Office'},
      //   { name: 'Other', value: 'Other'},
      //   { name: 'Out Building', value: 'Out Building'},
      //   { name: 'Panic Hardware', value: 'Panic Hardware'},
      //   { name: 'Parking Lot', value: 'Parking Lot'},
      //   { name: 'Playground', value: 'Playground'},
      //   { name: 'Pool Area', value: 'Pool Area'},
      //   { name: 'Railing/Stairs', value: 'Railing/Stairs'},
      //   { name: 'Recommendation', value: 'Recommendation'},
      //   { name: 'Roof', value: 'Roof'},
      //   { name: 'Safety Gear/Safety Item', value: 'Safety Gear/Safety Item'},
      //   { name: 'Seating Area', value: 'Seating Area'},
      //   { name: 'Signage', value: 'Signage'},
      //   { name: 'Smoke Detector/C02', value: 'Smoke Detector/C02'},
      //   { name: 'Sprinkler System', value: 'Sprinkler System'},
      //   { name: 'Stage', value: 'Stage'},
      //   { name: 'Storage Cabinet', value: 'Storage Cabinet'},
      //   { name: 'Storage Space', value: 'Storage Space'},
      //   { name: 'Street View', value: 'Street View'},
      //   { name: 'Surveillance System/Cameras', value: 'Surveillance System/Cameras'},
      //   { name: 'Work Station', value: 'Work Station'},
      //   { name: 'Work/Business Vehicle', value: 'Work/Business Vehicle'},
      // ];
    }

}
