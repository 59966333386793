import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Form} from './form.interface';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private http: HttpClient) { }

  getForms() {
    return this.http.get('/api/forms')
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getFormById(id: string) {
    return this.http.get(`/api/forms/${id}`)
      .toPromise()
      .then( form => {
        return form;
      });
  }

  updateForm(form: any) {
    return this.http.put(`/api/forms/${form._id}`, form)
      .toPromise()
      .then( data => {
        return data;
      });
  }
}
