import {Component, OnInit, ChangeDetectorRef, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MessageService} from 'primeng/api';
import loadImage from 'blueimp-load-image';
import {ISqlJob} from '../../jobs/sql-job.interface';
import {PhotoEditorComponent} from '../editor/photo-editor.component';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService} from 'primeng/api';
import { v4 as uuid4 } from 'uuid';
import { PhotosService } from '../photos.service';
import {IFile} from './upload.models';
import padStart from 'lodash/padStart';
import {HttpEvent} from '@angular/common/http';
import { Job } from '../../jobs/job';
import heic2any from 'heic2any';
import {SortableOptions} from 'sortablejs';
const orderBy = require('lodash/orderBy');
const sortBy = require('lodash/sortBy');
import * as  FileType from 'file-type/browser';
import { Loading } from 'notiflix';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  files: any[] = [];
  job: any;
  draggedFile: any;
  photoBucket: string;
  sortablejsOptions: any;
  photoLabels: any[];
  saveDisabled = true;
  filesToSign: IFile[] = [];

  @Input() scJob: ISqlJob;

  @Output() hasUnsavedPhotoChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private photosService: PhotosService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    this.sortablejsOptions = {
      onUpdate: async (evt) => {
        await this.savePhotos()
          .then(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Photo Changes Saved',
            });
          });
      },
      scroll: true,
      scrollSensitivity: 100,
      filter: '.nodrag'
    };

    this.photosService.getJob(this.scJob.jobId)
      .then(job => {
        this.job = job;
        if (job.inspectionPhotos.length) {
          return this.loadPhotos(job.inspectionPhotos);
        }
      })
      .then(() => {
        return this.photosService.getS3PhotoBucket();
      })
      .then(bucket => {
        this.photoBucket = bucket;
        return this.photosService.getPhotoLabels();
      })
      .then(photoLabels => {
        this.photoLabels = sortBy(photoLabels, ['label']);
        this.photoLabels = this.photoLabels.map(l => {
          return {
            index: l.index,
            label: l.label,
            value: l.label
          };
        });
        this.arrange(null, false);
      });
  }

  async loadPhotos(photos): Promise<void>{
    for (const [pIdx, p] of photos.entries()) {
      const imageArrayBuf = await this.photosService.getS3Photo(p.url);
      const imageFile: any = new File([imageArrayBuf], p.name, { type: p.type });
      imageFile.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(imageFile)));
      imageFile.resized = true;
      imageFile.id = p.photoId;
      imageFile.url = p.url;
      imageFile.label = p.label;
      imageFile.bucket = p.bucket || this.photoBucket;
      imageFile.key = p.key;
      imageFile.index = (p.index || p.index === 0) ? p.index : pIdx;
      imageFile.uploaded = true;
      imageFile.progress = 100;
      this.files.push(imageFile);
    }
  }

  async onUpload(event): Promise<void> {
    await this.signFiles();
    Promise.all(
      this.files.map(async f => {
        if (!f.uploaded) {
          return this.uploadPhoto(f);
        }
      })
    )
    .then( () => {
      this.savePhotos()
        .then(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Photo Upload Successful',
            detail: `Photos have been successfully upload and saved.`
          });
          this.saveDisabled = true;
          this.hasUnsavedPhotoChanges.emit(false);
        });
    })
    .catch(e => {
      this.messageService.add({
        severity: 'danger',
        summary: 'Photo Upload Failed',
        detail: e
      });
    });
  }

  async savePhotos(): Promise<Job> {
    const photos = this.files.filter(f => f.uploaded === true).map(f => {
        // @ts-ignore
        return {
          photoId: f.id,
          name: f.name,
          type: f.type,
          bucket: f.bucket || this.photoBucket,
          key: f.key,
          size: f.size,
          url: f.url,
          label: f.label,
          index: f.index
        };
    });
    return await this.photosService.savePhotos(this.scJob.jobId, photos);
  }

  uploadPhoto(file): Promise<void> {
    return new Promise((resolve, reject) => {
      this.photosService.uploadPhotoS3(file).subscribe( (event: HttpEvent<any>) => {
        try {
          switch (event.type) {
            case 1: // progress
              // @ts-ignore
              file.progress = parseInt(100.0 * event.loaded / event.total, 10);
              break;
            case 4: // finished
              // check for success and update the file
              // @ts-ignore
              if (event.status === 200) {
                file.uploaded = true;
                file.progress = 100;
                this.messageService.add({
                  severity: 'success',
                  summary: 'Photo Uploaded Successfully',
                  detail: `${file.name} was uploaded.`
                });
                resolve(file);
              } else {
                console.log(`Unable to upload ${file.name}`);
                console.log(event);
                this.messageService.add({severity: 'error', summary: 'Photo Upload Failed', detail: `${file.name} was not uploaded!`});
                reject(new Error('Unable to upload photo'));
              }
              // todo: need o add error handling
              break;
          }
        } catch (e) {
          reject(e);
        }

      });
    });
  }

  async onFileSelect(event): Promise<void> {
    // const filesToSign: IFile[] = [];
    this.saveDisabled = false;
    this.hasUnsavedPhotoChanges.emit(true);
      for (let f of event.currentFiles) {
      // check file type
      const fileType = await FileType.fromBlob(f);
      if (fileType.mime === 'image/heic') {
        Loading.Standard('Converting HEIC image to JPG...');
        const convertedImage = await heic2any({
          blob: f,
          toType: 'image/jpeg',
          quality: 1.0
        });
        // @ts-ignore
        convertedImage.name = f.name;
        f = convertedImage;
        Loading.Remove();
      }

      if (fileType.mime.includes('image/')) {
        if (!f.hasOwnProperty('resized')) {
          const canvas = await loadImage(f, { maxWidth: 640, canvas: true });
          const resizedBlob: Blob = await new Promise((resolve) => {
            canvas.image.toBlob((blob) => {
              resolve(blob);
            }, 'image/jpeg', 0.99);
          });

          const imageFile = new File([resizedBlob], f.name, { type: 'image/jpeg'});
          // @ts-ignore
          const objectUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(imageFile)));
          const fileIdx = event.currentFiles.findIndex(cFile => cFile.name === f.name);
          if (fileIdx > -1) {
            window.URL.revokeObjectURL(event.currentFiles[fileIdx].objectURL);
            event.currentFiles[fileIdx] = imageFile;
            event.currentFiles[fileIdx].objectURL = objectUrl;
            event.currentFiles[fileIdx].resized = true;
            const fileId = uuid4();
            event.currentFiles[fileIdx].id = fileId;
            event.currentFiles[fileIdx].bucket = this.photoBucket;
            event.currentFiles[fileIdx].url = '';
            event.currentFiles[fileIdx].label = '';
            event.currentFiles[fileIdx].uploaded = false;
            event.currentFiles[fileIdx].progress = 0;
            event.currentFiles[fileIdx].index = 0;
            const fileKey = `inspection-photos/${padStart(this.scJob.scJobId, 8, '0')}/${fileId}.jpg`;
            event.currentFiles[fileIdx].key = fileKey;
            this.files.push(event.currentFiles[fileIdx]);
            this.filesToSign.push({
              id: fileId,
              key: fileKey,
              contentType: 'image/jpeg'
            });
          }
        }
      }
    }
    event.currentFiles = [];
  }

  deletePhoto(file, index): void {
    this.confirmationService.confirm({
      key: 'photoConfirmDialog',
      message: `Delete ${file.name}${file.label ? ' (' + file.label + ')' : ''} ?`,
      accept: async () => {
        // remove the photo objectURL
        window.URL.revokeObjectURL(this.files[index].objectURL);
        const {bucket, key} = file;
        this.files.splice(index, 1);
        // remove photo
        if (file.uploaded) {
          const resp = await this.photosService.deleteS3Photo(bucket, key);
          console.log(resp);
        }
        this.savePhotos().then(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Photo Changes Saved',
            detail: `Photo ${file.name} was removed.`
          });
        });
      }
    });
  }

  editPhoto(file, i): void {
      const ref = this.dialogService.open(PhotoEditorComponent, {
        data: {
          file
        },
        header: `Editing Photo [ "${file.name.toUpperCase()}" - ${file.label ? file.label : 'No Label'} ]`,
        width: '50%',
        showHeader: true,
        closeOnEscape: false,
        closable: false,
      });

      ref.onClose.subscribe((cFile: any) => {
        this.files[i] = cFile;
        this.filesToSign.push({
          id: cFile.id,
          key: cFile.key,
          contentType: 'image/jpeg'
        });
        this.saveDisabled = false;
        this.hasUnsavedPhotoChanges.emit(true);
      });
  }

  saveLabel(file, e): void {
    const label = this.photoLabels.find(({ value }) => value === e.value);
    if (label) {
      file.index = label.index;
    } else {
      file.index = 0;
    }
    this.savePhotos()
      .then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Photo Label Changes Saved',
          detail: file.label
            ? `Photo ${file.name} was labeled as "${file.label}".`
            : `Photo ${file.name} label removed.`
        });
      });
  }

  dragStart(file): void {
    this.draggedFile = file;
    console.log('drag starting...');
    console.log(file);
  }

  dragEnd(): void {
    console.log('drag ending...');
  }

  // findIndex(file: any) {
  //   let index = -1;
  //   for(let i = 0; i < this.files.length; i++) {
  //     if (file.id === this.files[i].id) {
  //       index = i;
  //       break;
  //     }
  //   }
  //   return index;
  // }

  drop(): void {
    // if (this.draggedFile) {
    //   let draggedFileIndex = this.files.findIndex(f => this.draggedFile.id);
    //
    //   this.selectedProducts = [...this.selectedProducts, this.draggedProduct];
    //   this.availableProducts = this.availableProducts.filter((val,i) => i!=draggedProductIndex);
    this.draggedFile = null;

    console.log('item dropped...');
  }

  async signFiles(): Promise<void> {
    if (this.filesToSign && this.filesToSign.length) {
      const urls = await this.photosService.getSignedUrls(this.filesToSign);
      // assign urls to files by their id
      if (urls && urls.length) {
        for (const url of urls) {
          // let x = changeProps(this.files, { id: url.fileId }, { url: url.signedUrl });
          const file = this.files.find(f => f.id === url.fileId);
          if (file) {
            file.url = url.signedUrl;
            file.bucket = url.bucket;
          }
        }
      }
      this.filesToSign = [];
    }
  }

  arrange(e, canSave): void {
    for (const [pIdx, p] of this.files.entries()) {
      // sync label index
      if (p.label) {
        const label = this.photoLabels.find(l => l.label === p.label);
        if (label) {
          p.index = label.index;
        }
      } else {
        p.index = pIdx;
      }
    }
    this.files = orderBy(this.files, ['index'], ['asc']);
    if (canSave) {
      this.savePhotos()
        .then(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Photo Sort Order Saved',
          });
        });
    }
  }

}
