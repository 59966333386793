import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../app.breadcrumb.service';
import {SettingsService} from '../settings.service';
import {PhotoLabel} from './classes/photo-label.class';

@Component({
  selector: 'app-photo-labels',
  templateUrl: './photo-labels.component.html',
  styleUrls: ['./photo-labels.component.scss']
})
export class PhotoLabelsComponent implements OnInit {
  photoLabels: PhotoLabel[];

  constructor( private breadcrumbService: BreadcrumbService, private settingsService: SettingsService ) {
    this.breadcrumbService.setItems([
      {label: 'Settings / Photo Labels', routerLink: ['/settings/photo-labels']}
    ]);
  }

  ngOnInit(): void {
    this.settingsService.getPhotoLabels()
      .then(photoLabels => {
        return this.photoLabels = photoLabels;
      });
  }

  newLabel(): void {
    this.photoLabels.push({index: this.photoLabels.length + 1, label: `New Photo Label ${this.photoLabels.length + 1}`});
    this.savePhotoLabels();
  }

  deleteLabel(index): void {
    this.photoLabels.splice(index, 1);
  }

  rowReorder(e): void {
    this.photoLabels[e.dragIndex].index = e.dropIndex;
    this.savePhotoLabels();
  }

  editComplete(e): void {
    this.savePhotoLabels();
  }

  savePhotoLabels(): void {
    // todo: find duplicate labels?
    for (const [index, label] of this.photoLabels.entries()) {
      label.index = index;
    }
    this.settingsService.savePhotoLabels(this.photoLabels)
      .then( photoLabels => {
        this.settingsService.syncImageCaptions()
          .catch(e => console.error(`Unable to sync image captions on legacy database.\n${e}`));
      })
      .catch(e => console.error(`Unable to save photo labels.\n${e}`));
  }

}
