<p-confirmDialog key="photoConfirmDialog" header="Photo Action Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-panel>
<!--  <ng-template pTemplate="header">-->
<!--    Header content here-->
<!--  </ng-template>-->

  <p-fileUpload customUpload="true" (uploadHandler)="onUpload($event)" (onSelect)="onFileSelect($event)"
                multiple="multiple" accept="image/*,.heic" previewWidth="200" mode="advanced"
                [showUploadButton]="false" [showCancelButton]="false">

    <ng-template pTemplate="toolbar">
      <p-button icon="pi pi-upload" iconPos="left" label="Upload/Save" (onClick)="onUpload($event)" [disabled]="saveDisabled"></p-button>
      <p-button class="pull-right" icon="pi pi-sort-alt" iconPos="left" label="Sort by Label" (onClick)="arrange($event, true)" [disabled]="!files.length"></p-button>
    </ng-template>

    <ng-template pTemplate="content">
      <div>
        <div class="p-grid p-p-1">
          <div class="row" [sortablejs]="files" [sortablejsOptions]="sortablejsOptions">
            <div *ngFor="let file of files; let i = index;">
              <div class="p-col-2 drag-cursor">
                <div class="p-col-12" style="text-align: center">
                <img [src]="file.objectURL" [width]="200" (click)="editPhoto(file, i)"/>
              </div>
              <div class="p-col-12" style="align-content: center; width: 200px; margin-left: auto; margin-right: auto; margin-top: 0; margin-bottom: 5;">
                <progressbar [animate]="false" [value]="file.progress" type="info"><b>{{file.progress}}%</b></progressbar>
              </div>
              <div class="p-col-12" style="align-content: center; width: 200px;">
                <small>{{file.name}} ({{file.size}})</small>
              </div>
              <div class="p-col-12" style="text-align: center">
<!--                <p-inplace #c [preventClick]="true" (click)="c.activate($event)">-->
<!--                  <ng-template pTemplate="display">-->
<!--                    {{file.label ? file.label : 'No Label'}}-->
<!--                  </ng-template>-->
<!--                  <ng-template pTemplate="content">-->
<!--                    <input type="text" pInputText [(ngModel)]="file.label" (blur)="saveLabel(c, file, $event)">-->
<!--                  </ng-template>-->
<!--                </p-inplace>-->
                   <p-dropdown class="nodrag" [options]="photoLabels" [(ngModel)]="file.label"
                               placeholder="Select a photo label..." optionLabel="label" optionValue="value"
                               [showClear]="true" (onChange)="saveLabel(file, $event)"></p-dropdown>
              </div>
              <div class="p-col-12" style="align-content: center; width: 200px;">
                <button pButton pRipple type="button" icon="pi pi-trash" (click)="deletePhoto(file, i)"
                        class="p-button-rounded p-button-text p-button-danger nodrag"
                        pTooltip="Remove the photo" tooltipPosition="top"
                        showDelay="500" hideDelay="150"></button>
                <button pButton pRipple type="button" icon="pi pi-pencil" (click)="editPhoto(file, i)"
                        class="p-button-rounded p-button-text p-button-info nodrag"
                        pTooltip="Rotate and crop the photo" tooltipPosition="top"
                        showDelay="500" hideDelay="150"></button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!--      <div class="p-fileupload-row" *ngFor="let file of files; let i = index;">-->
    <!--        <div><img [src]="file.objectURL" [width]="200" /></div>-->
    <!--        <div>{{file.name}}</div>-->
    <!--        <div>{{file.size}}</div>-->
    <!--        <div>-->
    <!--          <button type="button" icon="pi pi-times" pButton (click)="remove($event,i)" [disabled]="uploading"></button>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--  <ng-template pTemplate="toolbar">-->
    <!--    <div>Upload 3 Files</div>-->
    <!--  </ng-template>-->

    <ng-template let-file pTemplate="file">
      <div></div>
    </ng-template>

  </p-fileUpload>


<!--  <ng-template pTemplate="footer">-->
<!--    Footer content here-->
<!--  </ng-template>-->
</p-panel>
