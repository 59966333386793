import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { AuthService, AuthLoginResponseData } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent {

  constructor(private authService: AuthService, private router: Router, private messageService: MessageService) {}

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const loginName = form.value.username;
    const password = form.value.password;

    let authObs: Observable<AuthLoginResponseData>;

    authObs = this.authService.login(loginName, password);

    authObs.subscribe(
      resData => {
        this.router.navigate(['/']);
      },
      errorMessage => {
        this.messageService.add({severity: 'error', summary: 'Unable to login', detail: errorMessage});
        // console.log(errorMessage);

      }
    );

    form.reset();
  }
}
